<script setup lang="ts">
const props = defineProps<{
  startDate: string;
  circleTextClass: string;
}>()

const timeLeft = ref(0)
const now = (new Date()).getTime()
const start = (new Date(props.startDate)).getTime()
const diff = start - now;
timeLeft.value = diff > 0 ? diff : 0;

let timer = null;

const fullDaysLeft = computed(() => {
  return Math.floor(timeLeft.value / 1000 / 60 / 60 / 24)
})

const dayPercentage = computed(() => {
  return Math.floor((fullDaysLeft.value / 60) * 100);
})

const fullHoursLeft = computed(() => {
  const daysLeftInMs = fullDaysLeft.value * 24 * 60 * 60 * 1000
  return Math.floor((timeLeft.value - daysLeftInMs) / 1000 / 60 / 60)
})
const hourPercentage = computed(() => {
  return Math.floor((fullHoursLeft.value / 60) * 100);
})

const fullMinutesLeft = computed(() => {
  const daysLeftInMs = fullDaysLeft.value * 24 * 60 * 60 * 1000
  const hoursLeftInMs = fullHoursLeft.value * 60 * 60 * 1000

  return Math.floor((timeLeft.value - daysLeftInMs - hoursLeftInMs) / 1000 / 60 )
})

const minutePercentage = computed(() => {
  return Math.floor((fullMinutesLeft.value / 60) * 100);
})

onMounted(() => {
  if (timeLeft.value > 0) {
    timer = setInterval(() => {
      timeLeft.value = timeLeft.value - 1000
      if (timeLeft.value <= 0) {
        clearInterval(timer)
      }
    }, 1000)
  }
})

onUnmounted(() => {
  clearInterval(timer)
})

</script>

<template>
  <v-row no-gutters class="mt-4" align-content="center" justify="center" :style="{ height: `${72 + 60}px` }">
    <v-col cols="auto" class="text-center">
      <v-progress-circular color="blue500" bg-color="gray200" :model-value="dayPercentage" :size="64" :width="6">
        <span class="font-weight-bold text-body-1" :class="circleTextClass">{{ fullDaysLeft }}</span>
      </v-progress-circular>
      <div class="d-block text-center mt-2">{{ $t('product.day', fullDaysLeft) }}</div>
    </v-col>
    <v-col cols="auto" class="text-center mx-3 mx-sm-6 mx-md-8">
      <v-progress-circular color="blue500" bg-color="gray200" :model-value="hourPercentage" :size="64" :width="6">
        <span class="font-weight-bold text-body-1" :class="circleTextClass">{{ fullHoursLeft }}</span>
      </v-progress-circular>
      <div class="d-block text-center mt-2">Std.</div>
    </v-col>
    <v-col cols="auto" class="text-center">
      <v-progress-circular color="blue500" bg-color="gray200" :model-value="minutePercentage" :size="64" :width="6">
        <span class="font-weight-bold text-body-1" :class="circleTextClass">{{ fullMinutesLeft }}</span>
      </v-progress-circular>
      <div class="d-block text-center mt-2">Min.</div>
    </v-col>

  </v-row>

</template>
